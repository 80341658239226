import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { changeEmailAPI } from "redux/actions";
import { useDispatch, useSelector } from "react-redux";
import { getLocalStorageValue } from "config/helper";

function ChangeEmail({ changeEmail, handleClose, setChangeEmailConfirm }) {
  const [userDetails, setUserDetails] = useState({
    newEmail: "",
    confirmEmail: "",
  });
  const dispatch = useDispatch();
  const clientId = getLocalStorageValue("RSAclient-id");
  const storeId = getLocalStorageValue("selected-store-id");
  const userToken = getLocalStorageValue("user-token");
  const memberNumber = getLocalStorageValue("member-number");

  const { changeEmailLoading, ChangeEmailmessage } = useSelector(
    ({ user }) => user
  );

  const { register, handleSubmit, errors } = useForm(userDetails);

  const ChangeEmailFun = (e) => {
    const { name, value } = e.target;
    setUserDetails({ ...userDetails, [name]: value });
  };

  const changeEmailAPICall = (data) => {
    if (data) {
      const formData = new FormData();
      formData.append("RSAClientId", clientId);
      formData.append("ClientStoreId", storeId);
      formData.append("member_number", memberNumber);
      formData.append("email_id", userDetails.newEmail);
      formData.append("user_token", userToken);
      var object = {};
      formData.forEach((value, key) => (object[key] = value));
      var jsonData = JSON.stringify(object);
      dispatch(changeEmailAPI(jsonData));
      setUserDetails({
        newEmail: "",
        confirmEmail: "",
      });
    }
  };

  return (
    <div className="modalContainer">
      <div className="modal change-email-header">
        <header className="modal_header ">
          <h2 className="modal_header-title">Change Email Address</h2>
        </header>
        <div className="chnage-email-wrapper">
        <main className="modal_conten">
          <div className="two-col-grid container change-email">
            <div className="two-col-grid-items row">
              <div className="form-control">
                <label>New Email Address*</label>
                <input
                  type="email"
                  name="newEmail"
                  id="newEmail"
                  onChange={ChangeEmailFun}
                  ref={register({ required: true, pattern: /^\S+@\S+$/i })}
                  value={userDetails.newEmail}
                />
                {errors.newEmail && (
                  <span className="text-danger display-block float-left">
                    {errors.newEmail.type === "required" &&
                      "New Email Address is required"}
                    {errors.newEmail.type === "pattern" &&
                      "The new email address entered is invalid - (e.g. email@domain.com)"}
                  </span>
                )}
              </div>
            </div>
            <div className="two-col-grid-items row">
              <div className="form-control">
                <label>Confirm Email Address*</label>
                <input
                  type="email"
                  name="confirmEmail"
                  id="confirmEmail"
                  onChange={ChangeEmailFun}
                  value={userDetails.confirmEmail}
                  ref={register({
                    required: true,
                    pattern: /^\S+@\S+$/i,
                    validate: (value) =>
                      value === userDetails.newEmail || "Emails do not match",
                  })}
                />
                {errors.confirmEmail && (
                  <span className="text-danger display-block float-left">
                    {errors.confirmEmail.type === "required" &&
                      "Confirm Email Address is required"}
                    {errors.confirmEmail.type === "pattern" &&
                      "The Confirm email address entered is invalid - (e.g. email@domain.com)"}
                    {errors.confirmEmail.type === "validate" &&
                      "Emails do not match"}
                  </span>
                )}
              </div>
            </div>
          </div>
        </main>

        <p style={{ color: "red", marginBottom: "1rem" }}>
          {ChangeEmailmessage}
        </p>
        <div className="expireContent">
          <footer className="modal_footer">
            <div className="modal_footer_btn">
              <div className="addBtn">
                <button
                  onClick={handleSubmit(changeEmailAPICall)}
                  rel="nofollow"
                  type="submit"
                >
                  {changeEmailLoading ? "Submitting" : "Submit"}
                </button>
              </div>
              <button
                className="modal-close"
                onClick={() => {
                  handleClose();
                  setChangeEmailConfirm();
                }}
              >
                Close
              </button>
            </div>
          </footer>
        </div>
        </div>
      </div>
    </div>
  );
}

export default ChangeEmail;
