import {
  FETCH_USER_REGISTER_DETAILS,
  FETCH_USER_REGISTER_DETAILS_SUCCESS,
  FETCH_USER_REGISTER_DETAILS_FAILURE,
  FETCH_USER_LOGIN_DETAILS,
  FETCH_USER_LOGIN_DETAILS_SUCCESS,
  FETCH_USER_LOGIN_DETAILS_FAILURE,
  FETCH_USER_DETAILS,
  FETCH_USER_DETAILS_SUCCESS,
  FETCH_USER_DETAILS_FAILURE,
  FETCH_CLIENT_DETAILS,
  FETCH_CLIENT_DETAILS_SUCCESS,
  FETCH_CLIENT_DETAILS_FAILURE,
  CUSTOMER_FORGOT_PASSWORD,
  CUSTOMER_FORGOT_PASSWORD_SUCCESS,
  CUSTOMER_FORGOT_PASSWORD_FAILURE,
  DELETE_USER_ACCOUNT,
  DELETE_USER_ACCOUNT_SUCCESS,
  DELETE_USER_ACCOUNT_FAILURE,
  CLEAR_REGISTER_MESSAGE,
  CLEAR_LOGIN_MESSAGE,
  CHANGE_EMAIL_UPDATE,
  CHANGE_EMAIL_UPDATE_SUCCESS,
  CHANGE_EMAIL_UPDATE_FAILURE,
} from "redux/constants/actionTypes";
import { getLocalStorageValue } from "config/helper";

let initialState = {
  userLoading: false,
  userDetails: null,
  userError: "",
  isUserLoggedIn: false,

  loginLoading: false,
  loginError: "",

  registerLoading: false,
  registerStatus: "",
  registerMessage: "",

  clientDetails: {},
  clientDetailsLoading: false,
  clientDetailsError: "",

  customerForgotPasswordData: {},
  customerForgotPasswordLoading: false,
  customerForgotPasswordError: "",

  deleteUserAccountDetails: {},
  deleteUserAccountLoading: false,
  deleteUserAccounError: "",
};

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_USER_REGISTER_DETAILS:
      return {
        ...state,

        registerLoading: true,
        registerStatus: "",
      };
    case FETCH_USER_REGISTER_DETAILS_SUCCESS:
      return {
        ...state,
        registerLoading: false,
        registerStatus: action.payload.status,
        registerMessage: action.payload.message,
      };
    case FETCH_USER_REGISTER_DETAILS_FAILURE:
      return {
        ...state,
        registerStatus: action.payload.error,
      };
    case CLEAR_REGISTER_MESSAGE:
      return {
        ...state,
        registerLoading: false,
        registerStatus: "",
        registerMessage: "",
      };

    case FETCH_USER_LOGIN_DETAILS:
      return {
        ...state,
        loginLoading: true,
        loginError: "",
      };
    case FETCH_USER_LOGIN_DETAILS_SUCCESS:
      return {
        ...state,
        loginLoading: false,
        userDetails: action.payload,
        isUserLoggedIn: true,
      };
    case FETCH_USER_LOGIN_DETAILS_FAILURE:
      return {
        ...state,
        loginError: action.payload.error,
      };
    case CLEAR_LOGIN_MESSAGE:
      return {
        ...state,
        loginLoading: false,
        loginError: "",
      };
    // Client details

    case FETCH_CLIENT_DETAILS:
      return {
        ...state,
        clientDetailsLoading: true,
      };
    case FETCH_CLIENT_DETAILS_SUCCESS:
      return {
        ...state,
        clientDetailsLoading: false,
        clientDetails: action.payload,
      };
    case FETCH_CLIENT_DETAILS_FAILURE:
      return {
        ...state,
        clientDetailsError: action.payload.error,
      };

    case FETCH_USER_DETAILS:
      return {
        ...state,
        userLoading: true,
      };
    case FETCH_USER_DETAILS_SUCCESS:
      return {
        ...state,
        userLoading: false,
        isUserLoggedIn: getLocalStorageValue("user-token") ? true : false,
      };
    case FETCH_USER_DETAILS_FAILURE:
      return {
        ...state,
        userError: action.payload.error,
      };
    // customer forgot password
    case CUSTOMER_FORGOT_PASSWORD:
      return {
        ...state,
        customerForgotPasswordDataLoading: true,
      };
    case CUSTOMER_FORGOT_PASSWORD_SUCCESS:
      return {
        ...state,
        customerForgotPasswordDataLoading: false,
        customerForgotPasswordData: action.payload,
      };
    case CUSTOMER_FORGOT_PASSWORD_FAILURE:
      return {
        ...state,
        customerForgotPasswordDataError: action.payload.error,
      };
    //Delete User account
    case DELETE_USER_ACCOUNT:
      return {
        ...state,
        deleteUserAccountLoading: true,
      };
    case DELETE_USER_ACCOUNT_SUCCESS:
      return {
        ...state,
        deleteUserAccountLoading: false,
        deleteUserAccountDetails: action.payload,
      };
    case DELETE_USER_ACCOUNT_FAILURE:
      return {
        ...state,
        deleteUserAccounError: action.payload.error,
      };
    case CHANGE_EMAIL_UPDATE:
      return {
        ...state,
        changeEmailLoading: true,
        ChangeEmailmessage: "",
        changeEmailData: {},
      };
    case CHANGE_EMAIL_UPDATE_SUCCESS:
      return {
        ...state,
        changeEmailLoading: false,
        changeEmailData: action.payload.message,
        ChangeEmailmessage: action.payload.message,
      };
    case CHANGE_EMAIL_UPDATE_FAILURE:
      return {
        ...state,
        changeEmailLoading: false,
        changeEmailData: action.payload,
        ChangeEmailmessage: action.payload.error,
      };
    default:
      return state;
  }
};

export default userReducer;
